<template>
  <div>
      <div class="w-100 h-100 noscroll">
        <iframe :src="gameUrl"></iframe>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            gameUrl: this.$route.params.url
        }
    },
}
</script>

<style scoped>
body {
  overflow-y: hidden !important;
  overflow-x: hidden !important;
}
iframe {
  height:calc(100vh);
  width:calc(100vw);
  box-sizing: border-box;
  border:0px;
  resize: none;
}
</style>